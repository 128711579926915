import { h } from 'preact'

import { SITE_DESCRIPTION, SITE_KEYWORDS } from '../js/config'
import { mapNodeToArticle } from '../js/graphql/utils'
import Home, { HomeFooter } from '../js/components/page/Home'

export const documentProps = {
  description: SITE_DESCRIPTION,
  keywords: SITE_KEYWORDS,
}

function HomePage(props) {
  const { posts = [], ...rest } = props

  const highlight = mapNodeToArticle(posts[0])
  const entries = posts.slice(1).map(mapNodeToArticle)

  return (
    <Home highlight={highlight} entries={entries} {...rest} />
  )
}

HomePage.Footer = HomeFooter

export const Page = HomePage
