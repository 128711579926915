import { h } from 'preact'
import classNames from 'clsx'

import { SiteContentArticleHighlights } from '../../molecule/article-highlights'
import { SiteFooterEntry } from '../../organism/SiteFooter'
import { TITLES } from '../../../config'
import BackgroundTitle from '../../atom/BackgroundTitle'
import SmartImg from '../../atom/nbb-smart-img'
import Figure from '../../atom/nbb-figure'
import { OptionalLink } from '../../atom/link-wrapper'

import styles from './index.module.scss'

const Home = ({ highlight, entries }) => (
  <SiteContentArticleHighlights
    marginDesktop
    highlight={highlight}
    entries={entries} />
)

export const HomeFooter = (props) => {
  const { book, game } = props

  if (!book && !game) {
    return null
  }

  const gameTitle = game.gamePlatform ? `${game.title} (${game.gamePlatform})` : game.title
  const bookTitle = book.bookAuthor ? `${book.title} by ${book.bookAuthor}` : book.title

  return (
    <div className={styles['p-home__footer']}>
      {game ? (
        <HomeFooterEntry
          title={TITLES.CURRENTLY_PLAYING_GAME}
          name={gameTitle}
          url={game.gameUrl}
          picture={game.featuredImage}
        />
      ) : null}

      {book ? (
        <HomeFooterEntry
          title={TITLES.CURRENTLY_READING_BOOK}
          name={bookTitle}
          url={book.bookUrl}
          picture={book.featuredImage}
        />
      ) : null}
    </div>
  )
}

const FOOTER_IMAGE_SIZES = '(min-width: 1980px) 260px, (min-width: 1280px) 14vw, (min-width: 768px) 260px, 70vw'

const HomeFooterEntry = ({ title, url, name, picture, ...rest }) => (
  <SiteFooterEntry {...rest}>
    <BackgroundTitle small light>{title}</BackgroundTitle>

    <Figure
      className={styles['p-home__footer-picture']}
      caption={(<OptionalLink href={url}>{name}</OptionalLink>)}
    >
      <OptionalLink href={url}>
        {picture ? (
          <SmartImg
            lazy
            maxSize={520}
            defaultSize={120}
            sizes={FOOTER_IMAGE_SIZES}
            src={picture.mediaItemUrl}
            alt={picture.altText || `Cover for "${name}"`}
            width={picture.mediaDetails && picture.mediaDetails.width}
            height={picture.mediaDetails && picture.mediaDetails.height}
            className={styles['p-home__cover']}
          />
        ) : (
          <span className={classNames(
            styles['p-home__cover'],
            styles['p-home__cover--placeholder'],
          )} />
        )}
      </OptionalLink>
    </Figure>
  </SiteFooterEntry>
)

export default Home
